import Base from '../../_config/base';

class Scrollinto extends Base {
  // eslint-disable-next-line no-useless-constructor
  constructor(el) {
    super(el);

    // eslint-disable-next-line no-unused-vars
    let lastKnownScrollPosition = 0;
    let ticking = false;
    const animateWhen = window.innerHeight - window.innerHeight / 3;

    function isInViewport(element) {
      const elementOffset = element.offsetTop;
      const windowOffset = window.pageYOffset;

      // add class if element is 33% visible
      if (windowOffset > elementOffset - animateWhen) {
        // animate
        element.classList.add('animate');
      }
    }

    isInViewport(el);

    document.addEventListener('scroll', () => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          isInViewport(el);
          ticking = false;
        });

        ticking = true;
      }
    });
  }
}

Scrollinto.className = 'Scrollinto';
export default Scrollinto;
