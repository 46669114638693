/* eslint-disable no-plusplus */
import Base from '../../_config/base';
import { getQueryParams } from '../../_config/utils';

class Projektgrid extends Base {
  constructor(el) {
    super(el);

    const filterBtn = el.querySelector('.projektgrid__options__filter');
    const searchBtn = el.querySelector('.projektgrid__options__search');
    let searchQuery = '';
    const searchInput = el.querySelector('.projektgrid__searchinput');
    const categoryWrapper = el.querySelector('.projektgrid__filter__categories');
    const categoryBtns = [...el.querySelectorAll('.kategorieBtns')];
    const projects = [...el.querySelectorAll('.projektgrid__grid__item')];
    const notFoundMsg = el.querySelector('.projektgrid__notfound');

    const filterParams = getQueryParams(window.location.href).filter;
    let filterString = '';

    filterBtn.onclick = () => {
      filterBtn.classList.toggle('projektgrid__options__filter-active');
      categoryWrapper.classList.toggle('projektgrid__filter__categories-wrapperActive');
      searchInput.classList.remove('projektgrid__searchinput-wrapperActive');
    };

    searchBtn.onclick = () => {
      searchInput.classList.toggle('projektgrid__searchinput-wrapperActive');
      filterBtn.classList.remove('projektgrid__options__filter-active');
      categoryWrapper.classList.remove('projektgrid__filter__categories-wrapperActive');
      searchInput.querySelector('input').focus();
    };

    const filterProjects = () => {
      let anyVisible = false; // Track if any project is visible

      projects.forEach((project) => {
        const projectCategoryId = project.getAttribute('categoryId');
        const projectSearchId = project.getAttribute('searchid');

        if (filterString !== '') {
          filterBtn.classList.add('projektgrid__options__filter-activeIndicator');
        } else {
          filterBtn.classList.remove('projektgrid__options__filter-activeIndicator');
        }

        if (
          (filterString === '' || filterString.includes(projectCategoryId))
          && (searchQuery === '' || projectSearchId.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          project.classList.remove('d-none');
          anyVisible = true; // Mark at least one project as visible
        } else {
          project.classList.add('d-none');
        }
      });

      // Show or hide the "not found" message
      if (!anyVisible) {
        notFoundMsg.classList.remove('d-none');
      } else {
        notFoundMsg.classList.add('d-none');
      }
    };

    const searchProjects = () => {
      let anyVisible = false; // Track if any project is visible

      projects.forEach((project) => {
        const projectCategoryId = project.getAttribute('categoryId');
        const projectSearchId = project.getAttribute('searchid');

        if (searchQuery !== '') {
          searchBtn.classList.add('projektgrid__options__search-activeIndicator');
        } else {
          searchBtn.classList.remove('projektgrid__options__search-activeIndicator');
        }

        if (
          (filterString === '' || filterString.includes(projectCategoryId))
          && (searchQuery === '' || projectSearchId.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          project.classList.remove('d-none');
          anyVisible = true; // Mark at least one project as visible
        } else {
          project.classList.add('d-none');
        }
      });

      // Show or hide the "not found" message
      if (!anyVisible) {
        notFoundMsg.classList.remove('d-none');
      } else {
        notFoundMsg.classList.add('d-none');
      }
    };

    // if there is a QueryParam in url, filter accordingly
    if (filterParams !== null) {
      categoryBtns.forEach((btn) => {
        const categoryBtnID = btn.getAttribute('categoryId');

        // if the param is acctually a category to filter
        if (filterParams.includes(categoryBtnID)) {
          // Check if categoryId is already in the filterString
          if (filterString.includes(categoryBtnID)) {
            // If it is, remove it from the filterString
            filterString = filterString.replace(categoryBtnID, '').trim();
            btn.classList.remove('projektgrid__filter__categories-active');
          } else {
            // If it isn't, add it to the filterString
            filterString += categoryBtnID;
            btn.classList.add('projektgrid__filter__categories-active');
          }

          filterProjects();
        }
      });
    } else {
      // remove the ?filter= if there is any
      const url = new URL(window.location.href); // Create a URL object
      url.searchParams.delete('filter'); // Remove the 'filter' query parameter
      window.history.pushState({}, '', url); // Update the URL without reloading the page
    }

    // input for the searchbar
    searchInput.querySelector('input').oninput = (e) => {
      // timeout so the search is waiting for the user to type
      setTimeout(() => {
        searchQuery = e.srcElement.value;
        searchProjects();
      }, 1000);
    };

    // Filter button click logic
    categoryBtns.forEach((btn) => {
      btn.onclick = () => {
        const categoryBtnID = btn.getAttribute('categoryId');

        // Deselect all other buttons
        categoryBtns.forEach((button) => {
          button.classList.remove('projektgrid__filter__categories-active');
        });

        // Toggle the clicked button's state
        if (filterString === categoryBtnID) {
          // If the button is already active, clear the filter
          filterString = '';
        } else {
          // Otherwise, set the filterString to the clicked button's categoryId
          filterString = categoryBtnID;
          btn.classList.add('projektgrid__filter__categories-active');
        }

        const url = new URL(window.location.href); // Create a URL object
        url.searchParams.set('filter', filterString); // Set or update the 'filter' query param
        window.history.pushState({}, '', url); // Update the URL without reloading the page

        filterProjects();
      };
    });
  }
}

Projektgrid.className = 'Projektgrid';
export default Projektgrid;
