import Swiper from 'swiper';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';
import Base from '../../_config/base';

class Hero extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, EffectFade, Autoplay],
      centeredSlides: true,
      slidesPerView: 1,
      effect: 'fade',
      speed: 500,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Hero.className = 'Hero';
export default Hero;
