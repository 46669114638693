import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Base from '../../_config/base';

class Lightbox extends Base {
  constructor(el) {
    super(el);
    const slider1 = document.querySelector('.swiper-impressionLightbox');
    const slider2 = document.querySelector('.swiper-plaeneLightbox');
    const close = el.querySelector('.lightbox__close');
    const triggers = document.querySelectorAll('.lightboxtrigger');

    close.onclick = () => {
      el.classList.add('d-none');
    };

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        el.classList.add('d-none');
      }
    });

    const sliderOpt1 = {
      modules: [Navigation],
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: '32px',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    const sliderOpt2 = {
      modules: [Navigation],
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: '32px',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    const swiper1 = slider1 ? new Swiper(slider1, sliderOpt1) : null;
    const swiper2 = slider2 ? new Swiper(slider2, sliderOpt2) : null;

    triggers.forEach((trigger) => {
      trigger.onclick = () => {
        const slideid = parseInt(trigger.getAttribute('slideid'), 10);
        if (trigger.classList.contains('projektdetail__impressions__item')) {
          document.querySelector('#impressionLightbox').classList.toggle('d-none');
        } else {
          document.querySelector('#plaeneLightbox').classList.toggle('d-none');
        }

        setTimeout(() => {
          if (swiper1) swiper1.slideTo(slideid);
          if (swiper2) swiper2.slideTo(slideid);
        }, 100);
      };
    });
  }
}

Lightbox.className = 'Lightbox';
export default Lightbox;
