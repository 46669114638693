import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);
    this.hamburger = el.querySelector('.hamburger');
    this.parentItem = el.querySelector('.has-children > a');
    this.hamburgerMenu = el.querySelector('.hamburger-menu');
    this.logo = el.querySelector('.header__logo');
    this.body = document.getElementById('body');

    this.hamburger.onclick = (e) => {
      e.preventDefault();
      this.hamburger.querySelector('#hamburger-icon').classList.toggle('active');
      this.logo.classList.toggle('header__logo--active');
      this.body.classList.toggle('overflow-hidden');
      el.classList.toggle('active');
      this.hamburgerMenu.classList.remove('active');
      el.querySelector('.hamburger').classList.toggle('linkCloseCursor');
      this.logo.classList.toggle('header__logo__firstvisit');
    };

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' && this.logo.classList.contains('header__logo--active')) {
        el.querySelector('.hamburger').classList.toggle('linkCloseCursor');
        this.hamburger.querySelector('#hamburger-icon').classList.toggle('active');
        this.logo.classList.toggle('header__logo--active');
        this.body.classList.toggle('overflow-hidden');
        el.classList.toggle('active');
        this.hamburgerMenu.classList.remove('active');
        this.logo.classList.toggle('header__logo__firstvisit');
      }
    });
  }
}

Header.className = 'Header';
export default Header;
