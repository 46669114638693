import Base from '../../_config/base';

class Projektdetail extends Base {
  constructor(el) {
    super(el);
    const lightswitch1 = el.querySelector('.lightswitch1');
    const lightswitch2 = el.querySelector('.lightswitch2');
    const lightswitch3 = el.querySelector('.lightswitch3');
    const lightswitchcontent1 = el.querySelector('.lightswitchcontent1');
    const lightswitchcontent2 = el.querySelector('.lightswitchcontent2');
    const showAll = el.querySelector('.projektdetail__morebuttons__showmore');
    const allImpressions = [...el.querySelectorAll('.projektdetail__impressions__item')];
    const allPlans = [...el.querySelectorAll('.projektdetail__plaene__item')];
    const statsButton = el.querySelector('.statsButton');

    const toggleLightSwitch = () => {
      lightswitch1.classList.toggle('projektdetail__options__items-active');
      lightswitch2.classList.toggle('projektdetail__options__items-active');
      lightswitchcontent1.classList.toggle('projektdetail__galery-active');
      lightswitchcontent1.classList.toggle('projektdetail__galery-inactive');
      lightswitchcontent2.classList.toggle('projektdetail__galery-active');
      lightswitchcontent2.classList.toggle('projektdetail__galery-inactive');
      lightswitch3.querySelector('.projektdetail__morebuttons__lightswitch__impressions').classList.toggle('d-none');
      lightswitch3.querySelector('.projektdetail__morebuttons__lightswitch__plaene').classList.toggle('d-none');
    };

    statsButton.onclick = () => {
      window.scrollTo({
        top: el.querySelector('#stats').offsetTop - 64,
        behavior: 'smooth',
      });
    };

    lightswitch1.onclick = () => {
      if (!lightswitch1.classList.contains('projektdetail__options__items-active')) {
        toggleLightSwitch();
        window.scrollTo({
          top: lightswitch1.offsetTop - 64,
          behavior: 'smooth',
        });
      }
      if (allImpressions.length <= 6) {
        showAll.classList.add('d-none');
      } else {
        showAll.classList.remove('d-none');
      }
    };

    if (lightswitch2 !== null) {
      lightswitch2.onclick = () => {
        if (!lightswitch2.classList.contains('projektdetail__options__items-active')) {
          toggleLightSwitch();
          window.scrollTo({
            top: lightswitch2.offsetTop - 64,
            behavior: 'smooth',
          });
        }
        if (allPlans.length <= 6) {
          showAll.classList.add('d-none');
        } else {
          showAll.classList.remove('d-none');
        }
      };
    }

    if (lightswitch2 !== null) {
      lightswitch3.onclick = () => {
        toggleLightSwitch();
        if (lightswitch2.classList.contains('projektdetail__options__items-active')) {
          window.scrollTo({
            top: lightswitch2.offsetTop - 64,
            behavior: 'smooth',
          });
        } else if (lightswitch1.classList.contains('projektdetail__options__items-active')) {
          window.scrollTo({
            top: lightswitch1.offsetTop - 64,
            behavior: 'smooth',
          });
        }
      };
    }

    showAll.onclick = () => {
      showAll.classList.add('d-none');
      if (lightswitch1.classList.contains('projektdetail__options__items-active')) {
        allImpressions.forEach((impressionen) => {
          impressionen.classList.remove('projektdetail__galery__hidden');
        });
      } else if (lightswitch2.classList.contains('projektdetail__options__items-active')) {
        allPlans.forEach((plans) => {
          plans.classList.remove('projektdetail__galery__hidden');
        });
      }
    };
  }
}

Projektdetail.className = 'Projektdetail';
export default Projektdetail;
