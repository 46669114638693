import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Base from '../../_config/base';

class Newsteaser extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination, Autoplay],
      spaceBetween: '4px',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      loop: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Newsteaser.className = 'Newsteaser';
export default Newsteaser;
