/* eslint-disable import/no-extraneous-dependencies */
import { gsap } from 'gsap';
import Base from '../../_config/base';

class Cursor extends Base {
  // eslint-disable-next-line no-useless-constructor
  constructor(el) {
    super(el);
    if (document.querySelector('.cursor') === null) return;
    // if mobile or tablet without window.innerwidth, return
    function isMobileOrTablet() {
      // Regular expression to match common mobile and tablet user agent patterns
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }

    if (isMobileOrTablet() || window.innerWidth < 1024) {
      document.querySelector('.cursor').style.display = 'none';
      return;
    }

    const cursor = document.querySelector('.cursor');
    const allProjectCursor = [...document.querySelectorAll('.linkProjectCursor')];
    const allPrevCursor = [...document.querySelectorAll('.linkPrevCursor')];
    const allNextCursor = [...document.querySelectorAll('.linkNextCursor')];
    const allGaleryCursor = [...document.querySelectorAll('.linkGaleryCursor')];
    const allCloseCursor = [...document.querySelectorAll('.linkCloseCursor')];
    const allMoreCursor = [...document.querySelectorAll('.linkMoreCursor')];
    const allExternalCursor = [...document.querySelectorAll('.linkExternalCursor')];
    const allBackCursor = [...document.querySelectorAll('.linkBackCursor')];
    const allMailCursor = [...document.querySelectorAll('.linkMailCursor')];

    // eslint-disable-next-line max-len
    const allCursors = allProjectCursor.concat(allPrevCursor, allNextCursor, allGaleryCursor, allCloseCursor, allMoreCursor, allExternalCursor, allBackCursor, allMailCursor);

    gsap.set('.cursor', {
      xPercent: -25, yPercent: -25, opacity: 0, scale: 0.2,
    });

    const xToBig = gsap.quickTo('.cursor', 'x', { duration: 0.3, ease: 'power4' });
    const yToBig = gsap.quickTo('.cursor', 'y', { duration: 0.3, ease: 'power4' });

    window.addEventListener('mousemove', (e) => {
      xToBig(e.clientX);
      yToBig(e.clientY);
    });

    // if mouse leaves window, reduse opacity of cursor
    document.addEventListener('mouseleave', () => {
      gsap.to('.cursor', { opacity: 0 });
    });

    allCursors.forEach((link) => {
      link.addEventListener('mouseenter', () => {
        gsap.to('.cursor', { scale: 1, opacity: 1 });

        if (link.classList.contains('linkProjectCursor')) {
          cursor.className = 'cursor cursor-project-active';
        } else if (link.classList.contains('linkMoreCursor')) {
          cursor.className = 'cursor cursor-more-active';
        } else if (link.classList.contains('linkGaleryCursor')) {
          cursor.className = 'cursor cursor-galery-active';
        } else if (link.classList.contains('linkPrevCursor')) {
          cursor.className = 'cursor cursor-prev-active';
        } else if (link.classList.contains('linkNextCursor')) {
          cursor.className = 'cursor cursor-next-active';
        } else if (link.classList.contains('linkCloseCursor')) {
          cursor.className = 'cursor cursor-close-active';
        } else if (link.classList.contains('linkExternalCursor')) {
          cursor.className = 'cursor cursor-extern-active';
        } else if (link.classList.contains('linkBackCursor')) {
          cursor.className = 'cursor cursor-back-active';
        } else if (link.classList.contains('linkMailCursor')) {
          cursor.className = 'cursor cursor-mail-active';
        }
      });

      // link.onclick = () => {
      //   gsap.to('.cursor', { scale: 1.1, duration: 0.2 });
      //   setTimeout(() => {
      //     gsap.to('.cursor', { scale: 1, duration: 0.2 });
      //   }, 100);
      // };

      link.addEventListener('mouseleave', () => {
        gsap.to('.cursor', { scale: 0.2, opacity: 0 });
      });
    });
  }
}

Cursor.className = 'Cursor';
export default Cursor;
