import Base from '../../_config/base';

class Microinteractions extends Base {
  // eslint-disable-next-line no-useless-constructor
  constructor(el) {
    super(el);
    const copyToast = document.querySelector('.microinteractions__copy');
    const readProgress = document.querySelector('.microinteractions__readprogress');

    if (copyToast !== null) {
      document.oncopy = () => {
        copyToast.classList.add('microinteractions__toast-visible');
        setTimeout(() => {
          copyToast.classList.remove('microinteractions__toast-visible');
        }, 3000);
      };
    }

    function updateProgressBar() {
      // eslint-disable-next-line max-len
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPosition = window.scrollY;

      // Calculate the progress percentage
      const scrollProgress = (scrollPosition / scrollHeight) * 100;

      // Set the width of the progress bar
      readProgress.style.width = `${scrollProgress}%`;
    }

    if (readProgress !== null) {
      window.onscroll = updateProgressBar;
      updateProgressBar();
    }

    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key === 'z') {
        // eslint-disable-next-line no-restricted-globals
        history.go(-1);
      }
    });
  }
}

Microinteractions.className = 'Microinteractions';
export default Microinteractions;
