import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Base from '../../_config/base';

class Newsarticle extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation],
      centeredSlides: true,
      slidesPerView: 1,
      loop: true,
      spaceBetween: '64px',
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Newsarticle.className = 'Newsarticle';
export default Newsarticle;
