/* eslint-disable import/no-extraneous-dependencies */
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import Base from '../../_config/base';

class Contact extends Base {
  constructor(el) {
    super(el);
    const submitButton = el.querySelector('.submit');
    const formSuccess = el.querySelector('.form__success');
    const formError = el.querySelector('.form__error');
    const formular = el.querySelector('.form');
    const loadSpinner = el.querySelector('.contactform__inner--spinner');
    this.body = document.getElementById('body');

    if (formular !== null) {
      const serialize = (form) => {
        // Setup our serialized data
        const serialized = [];
        // Loop through each field in the form
        for (let i = 0; i < form.elements.length; i += 1) {
          const field = form.elements[i];
          // Skip fields that should not be serialized
          // eslint-disable-next-line no-continue
          if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
          // If a multi-select, get all selections
          if (field.type === 'select-multiple') {
            for (let n = 0; n < field.options.length; n += 1) {
              // Skip unselected options
              // eslint-disable-next-line no-continue
              if (!field.options[n].selected) continue;
              serialized.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.options[n].value)}`);
            }
          } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            // Serialize other fields
            serialized.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`);
          }
        }
        return serialized.join('&');
      };

      const sendAnimation = () => {
        const paperplane = submitButton.querySelector('.icon--paperplane');

        // Ensure MotionPathPlugin is registered
        gsap.registerPlugin(MotionPathPlugin);

        const path1 = 'M44.653,-19.33901 C44.653,-19.33901 405.941,176.997 552.523,72.46 744.826,-64.736 738.18,-411.03801 738.18,-411.03801 ';

        const tl = gsap.timeline({});

        tl.to(paperplane, {
          x: -50,
          y: 40,
          rotate: -10,
          // scale: 20, // Scale during flight
          duration: 0.7, // Duration of the animation
          ease: 'ease', // Smooth easing for flight
        });

        tl.to(paperplane, {
          motionPath: {
            path: path1,
            alignOrigin: [1, 1],
            autoRotate: 60,
          },
          // scale: 20, // Scale during flight
          duration: 2, // Duration of the animation
          scale: 3,
          opacity: 0,
          ease: 'ease', // Smooth easing for flight
        });
      };

      // Ajax call to submit the form and show the success page
      const submitContactForm = (ev) => {
        ev.preventDefault();
        submitButton.disabled = true;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.onload = () => {
          if (xhr.status === 200) {
            // Show success message and hide form
            formSuccess.classList.remove('form__msg-hidden');
            formError.classList.add('form__msg-hidden');
            formular.classList.add('contactform__inner__hidden');
            loadSpinner.classList.add('d-none');
          } else {
            formSuccess.classList.add('form__msg-hidden');
            formError.classList.remove('form__msg-hidden');
            formular.classList.add('contactform__inner__hidden');
            loadSpinner.classList.add('d-none');
            submitButton.disabled = false; // Re-enable button on error
          }
        };
        sendAnimation();
        loadSpinner.classList.remove('d-none');
        setTimeout(() => {
          xhr.send(serialize(ev.target));
        }, 3000);
      };

      // Add event listener to submit the form
      formular.addEventListener('submit', submitContactForm);
    }
  }
}

Contact.className = 'Contact';
export default Contact;
