// import Button from './01-atoms/button/button';
// import Attachment from './01-atoms/inputs/attachment';
// import Range from './01-atoms/inputs/range';
// import Select from './01-atoms/inputs/select';
// import Accordion from './02-molecules/accordion/accordion';
// import Alerts from './02-molecules/alerts/alerts';
import Popup from './02-molecules/popup/popup';
import Slider from './02-molecules/slider/slider';
// import Teasers from './02-molecules/threeTeaser/teasers';
// import Filter from './02-molecules/filter/filter';
// import Dialogues from './02-molecules/dialogues/dialogues';
import Hero from './02-molecules/hero/hero';
import Projektgrid from './02-molecules/projektgrid/projektgrid';
import Projektdetail from './02-molecules/projektdetail/projektdetail';
import Lightbox from './02-molecules/lightbox/lightbox';
import Newsteaser from './02-molecules/newsteaser/newsteaser';
import Contact from './02-molecules/contactBlock/contact';
import Newsarticle from './02-molecules/newsarticle/newsarticle';
import Header from './03-organisms/header/header';
import Scrollsmoother from './03-organisms/globalanimations/scrollsmoother';
import Cursor from './03-organisms/globalanimations/cursor';
import Scrollinto from './03-organisms/globalanimations/scrollinto';
import Microinteractions from './03-organisms/globalanimations/microinteractions';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Scrollsmoother = Scrollsmoother.init();
  // Component.Button = Button.init();
  // Component.Range = Range.init();
  Component.Projektgrid = Projektgrid.init();
  Component.Newsarticle = Newsarticle.init();
  Component.Newsteaser = Newsteaser.init();
  Component.Projektdetail = Projektdetail.init();
  Component.Hero = Hero.init();
  // Component.Dialogues = Dialogues.init();
  // Component.Accordion = Accordion.init();
  // Component.Alerts = Alerts.init();
  Component.Contact = Contact.init();
  Component.Popup = Popup.init();
  Component.Slider = Slider.init();
  Component.Lightbox = Lightbox.init();
  // Component.Attachment = Attachment.init();
  // Component.Select = Select.init();
  Component.Header = Header.init();
  // Component.Teasers = Teasers.init();
  // Component.Filter = Filter.init();
  Component.Cursor = Cursor.init();
  Component.Scrollinto = Scrollinto.init();
  Component.Microinteractions = Microinteractions.init();
});
